<template>
  <div id="factsheet">
    <div class="heading text-6xl font-semibold">Factsheet</div>

    <div class="factsheet_body mt-10 md:mt-20">
      <div class="card grid grid-cols-2">
        <img src="../../assets/images/purpose.svg" alt="" />
        <div class="card_text xl:text-lg grid items-center md:p-10">
          NASIDA was established under the NASIDA Law, 2020 to initiate, promote,
          facilitate, and coordinate investments in Nasarawa state, through greenfield,
          public-private partnerships, and privatizations concessions and
          commercialization of the state-owned assets and other related matters.
        </div>
      </div>
      <div class="card reverse grid grid-cols-2">
        <div class="card_text xl:text-lg grid items-center md:p-10">
          NASIDA set up primarily due to the clear absence of an institutionalized
          investment promotion and facilitation agency that acts as a one-stop-shop in
          dealing with all investments in the State. Current practice does not foster
          continuity, knowledge retention, and institutional memory; all of which are key
          for effective investment promotion.
        </div>
        <img src="../../assets/images/rationable.svg" alt="" />
      </div>
      <div class="card grid grid-cols-2">
        <img src="../../assets/images/innovation.svg" alt="" />
        <div class="card_text xl:text-lg grid items-center md:p-10">
          There is also significant private sector participation in the Board of the
          Agency that infuses transparency, efficiency, and effectiveness. NASIDA Law
          allows for an Infrastructure Fund, the first of its kind, where 5% of the
          State’s IGR accrues monthly. This will be utilized to de-risk and improve the
          bankability of infrastructure projects in the State.
        </div>
      </div>
      <div class="card reverse grid grid-cols-2">
        <div class="card_text xl:text-lg grid items-center md:p-10">
          NASIDA Law encapsulates the State PPP Law and sets out a clear path for engaging
          in PPPs, and it provides the necessary comfort for the private sector. It
          provides the institutional and legal framework for Public-Private Partnerships,
          concessions, commercialization and privatization transactions.
        </div>
        <img src="../../assets/images/partnership.svg" alt="" />
      </div>
      <div class="card grid grid-cols-2">
        <img src="../../assets/images/invest-management.svg" alt="" />
        <div class="card_text xl:text-lg grid items-center md:p-10">
          NASIDA is structured to house all State Investments, supported with the
          necessary mandate to invest in viable investment as may be determined by the
          Governing Board from time to time.
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./factsheet.page.js"></script>
<style src="./factsheet.page.css" scoped lang="css"></style>
